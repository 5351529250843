<template>
  <base-section
    id="recent-works"
    space="0"
  >
    <v-container
      class="py-0"
      fluid
    >
      <v-row justify="center">
        <v-theme-provider dark>
          <v-col
            class="accent extra-padding"
            cols="12"
            md="4"
          >
            <v-responsive
              class="mx-auto"
              max-width="360"
            >
              <base-title
                mobile-size="title"
                size="display-1"
                space="2"
                title="Product Listing"
              />

              <base-divider color="primary" />

              <base-body :space="$vuetify.breakpoint.smAndDown ? 0 : undefined">
                Nanotech Solutions offers a large variety of products as listed below.
              </base-body>

              <v-item-group
                v-model="models"
                class="hidden-sm-and-down"
              >
                <template v-for="(model, i) in models">
                  <v-item
                    :key="i"
                  >
                    <v-btn
                      class="justify-start mb-1"
                      color="grey lighten-1"
                      small
                      text
                      width="125"
                      @click="c = category"
                    >
                      {{ model.title }}
                    </v-btn>
                  </v-item>

                  <br
                    v-if="i < models.length"
                    :key="`br-${i}`"
                  >
                </template>
              </v-item-group>
            </v-responsive>
          </v-col>
        </v-theme-provider>

        <v-col
          class="d-flex align-center"
          cols="12"
          md="8"
        >
          <projects
            :filter="filter"
            height="325"
            width="325"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionRecentWork',

    components: {
      Projects: () => import('@/views/sections/Projects'),
    },

    data: () => ({
      models: [
        {
          title: 'Activated Carbon (coconut shell)',
          src: require('@/assets/activated carbon.jpg'),
        },
        {
          title: 'Antioxidant',
          src: require('@/assets/Antioxidants.png'),
        },
        {
          title: 'Antitack Powder Free',
          src: require('@/assets/antitack powder free.jpg'),
        },
        {
          title: 'Antifoam',
          src: require('@/assets/antifoam.jpg'),
        },
        {
          title: 'Acrylic Polymer Binder',
          src: require('@/assets/acrylic polymer binder.jpg'),
        },
        {
          title: 'Biocides',
          src: require('@/assets/biocides.jpg'),
        },
        {
          title: 'Coagulant',
          src: require('@/assets/coagulant.jpg'),
        },
        {
          title: 'Dispersing Agents',
          src: require('@/assets/Dispersing agents.jpeg'),
        },
        {
          title: 'Fragrance/Flavour',
          src: require('@/assets/Fragrance_Flavours.jpg'),
        },
        {
          title: 'Formic Acid',
          src: require('@/assets/formic acid.jpg'),
        },
        {
          title: 'Nitrile Butadiene Latex',
          src: require('@/assets/nitrile butadiene latex.jpg'),
        },
        {
          title: 'PVC Resin Paste',
          src: require('@/assets/PVC resin paste.jpg'),
        },
        {
          title: 'Polyurethane Wet Donning',
          src: require('@/assets/polyurethane wet donning.jpg'),
        },
        {
          title: 'Polyurethane Polymer',
          src: require('@/assets/polyurethane polymer.jpg'),
        },
        {
          title: 'Titanium Dioxide',
          src: require('@/assets/titanium dioxide.jpeg'),
        },
        {
          title: 'Wetting Agents',
          src: require('@/assets/Wetting agents.jpg'),
        },
        {
          title: 'Wetting Donning Agents',
          src: require('@/assets/wetting donning agents.jpg'),
        },
      ],
    }),

    methods: {
      filter (card) {
        return card.category === this.category || this.category === 'All'
      },
    },
  }
</script>
